// import "./PersonalDevelopment.Script";

import expand_collapse_arrow from "assets/images/expand-collapse-arrow.svg";
import Image from "components/Image";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import Card from "components/PersonalDevelopment/Card/Card";
import MiscAttributes from "components/MiscAttributes";

const PersonalDevelopment = () => {
  let { user } = useSelector((state) => state.user);

  // useEffect(() => {
  //   personalDevelopmentToggle();
  // }, [user]);

  const PersonalDevelopmentData = [
    {
      title: "Trips, Events & Experiences",
      dataArray: user?.trips_events_experiences.sort((a, b) => {
        return new Date(b.startDate) - new Date(a.endDate);
      }),
      attachment: [
        {
          comments:
            "“I’ve always admired players like Nemanja Vidic and Jaap Stam for their physicality, dynamic speed and power. I am focused on becoming as strong and reliable in my defensive duties.”",
        },
      ],
    },
    {
      title: "Presentation & Awards",
      dataArray: user?.presentation_awards,
      attachment: [
        {
          comments:
            "“I’ve always admired players like Nemanja Vidic and Jaap Stam for their physicality, dynamic speed and power. I am focused on becoming as strong and reliable in my defensive duties.”",
        },
      ],
    },
    {
      title: "Community / Charity Work",
      dataArray: user?.community_charity_support,
      attachment: [
        {
          comments:
            "“I’ve always admired players like Nemanja Vidic and Jaap Stam for their physicality, dynamic speed and power. I am focused on becoming as strong and reliable in my defensive duties.”",
        },
      ],
    },
  ];

  useEffect(() => {
    $(document).ready(function () {
      // console.log($(".list-group-item").get());
      $(".list-group-item").click(function (event) {
        event.preventDefault(); // Prevent the default jump-to behavior of anchor links
        // console.log("this", this);
        if ($(this).hasClass("active")) {
          $(".personal-development-toggle-text").removeClass("active");
          $(".pd-arrow").addClass("rotate");
          $(this).removeClass("active");
          // Hide other sections with fade animation
          // eslint-disable-next-line
          $(".section-collapse.show").not(target).removeClass("show");
        } else {
          $(".list-group-item").removeClass("active");
          $(this).addClass("active");
          var target = $(this).attr("href");
          // eslint-disable-next-line
          var $targetSection = $(target);
          // console.log("$targetSection", $targetSection);
          // Hide other sections with fade animation
          $(".section-collapse.show").not(target).removeClass("show");
          $(target).addClass("show");
          // Toggle the clicked section with fade animation
          // $targetSection.collapse("toggle");

          // Remove 'active' class from all 'active-line' and 'personal-development-toggle-text' elements
          $(".personal-development-toggle-text").removeClass("active");
          $(".pd-arrow").addClass("rotate");
          // Add 'active' class to the clicked 'active-line' and 'personal-development-toggle-text' elements
          $(this).find(".personal-development-toggle-text").addClass("active");
          $(this).find(".pd-arrow").removeClass("rotate");
        }
      });
    });
  }, [user]);

  return (
    <>
      <div id="personal-development" className="section">
        <div className="col d-flex flex-column align-items-start justify-content-center content-box">
          <h2 id="personal-development-title">
            My <span className="font-weight-bold">Personal Development</span>
          </h2>
          <div className="col-12 col p-0">
            <div className="row">
              <div className="col-md-12 p-0">
                <div
                  id="personal-development-toggles"
                  className="list-group d-flex flex-column"
                >
                  {PersonalDevelopmentData?.map((data, i) => (
                    <>
                      {data?.dataArray?.length > 0 && (
                        <div id={`pd-${i + 1}-toggle-section`}>
                          <a
                            key={i}
                            href={`#item${i + 1}`}
                            className={`list-group-item list-group-item-action  ${
                              i === 0 ? "active" : "active"
                            } pl-0`}
                          >
                            <div className="d-flex flex-row nav-item-outer align-items-center justify-content-between w-100">
                              <p
                                className={`personal-development-toggle-text  ${
                                  i === 0 ? "active" : "active"
                                }`}
                              >
                                {data?.title}
                                {/* {user?.} */}
                              </p>
                              <Image
                                className="pd-arrow"
                                src={expand_collapse_arrow}
                              />
                            </div>
                          </a>

                          <div
                            id={`item${i + 1}`}
                            className={`collapse section-collapse  ${
                              i === 0 ? "show" : "show"
                            }`}
                          >
                            <div className="row d-flex flex-column personal-development-content-row">
                              {data?.dataArray?.map((details, j) => (
                                <Card
                                  PersonalDevelopmentDetails={details}
                                  key_={i + "" + j}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>

            <MiscAttributes Category="personal development" />
          </div>
        </div>
      </div>

      {user?.organization === "football" && (
        <div
          id="tutor-comment"
          className="d-flex justify-content-center align-items-center section"
        >
          {user?.footballAttribute === true && (
            <div className="col-6 col comment-main">
              <h5 className="text-center light-grey">
                {user?.football_bio ? user?.football_bio : ""}
              </h5>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PersonalDevelopment;
