// import "assets/css/main.css";
import Interactive from "pages/assets/Sports/Interactive";
import Traditional from "pages/assets/Sports/Traditional";
import { Route, Routes } from "react-router-dom";
import "./App.css";

function App() {
  // const params = useParams();
  // let { user, validExpiryDate } = useSelector((state) => state.user);
  // useEffect(() => {
  //   containerWidth();
  // }, []);

  // useEffect(() => {
  //   if (validExpiryDate && validExpiryDate?.valid === true) {
  //     dispatch(loaderAction.startLoader());
  //     dispatch(getUserAction.getUser(params.userId));
  //   }
  // }, [dispatch, validExpiryDate]);

  // useEffect(() => {
  //   dispatch(loaderAction.startLoader());
  //   dispatch(getValidExpiryDateAction.getValidateExpiryDate(params.userId));
  // }, [dispatch]);
  // const getExpiryDatePopup = () => {
  //   alert("Your cv has been expired");
  // };
  return (
    <>
      <Routes>
        <Route path="/cv/:userId" element={<Interactive />} />
        <Route exact path="/cv/traditional/:userId" element={<Traditional />} />
      </Routes>
    </>
  );
}
export default App;
