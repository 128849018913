import { useSelector } from "react-redux";
import Image from "components/Image/Image";
import MiscAttributes from "components/MiscAttributes";

const AboutMe = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <>
      <div
        id="aboutMe"
        className="flex-fill d-flex flex-row gut-100 justify-content-center section"
      >
        <div className="col-left col-md-6 d-flex flex-column align-items-end justify-content-center content-box about-me-info">
          <h2 id="name" className="text-right">
            {user?.name ? user?.name.split(" ")[0] : ""} <br id="nameBreak" />
            <span className="font-weight-bold">
              {user?.name ? user?.name.split(" ")[1] : ""}
            </span>
          </h2>
          <h5 id="cvIntro" className="text-right">
            {user?.bio ? user?.bio : ""}
          </h5>
          <div className="d-flex flex-row align-items-center flex-nowrap content-row">
            <p id="age">{user?.age ? `Age ${user?.age}` : ""}</p>
            {user?.age && <span className="circle-separator">•</span>}
            {user?.height && (
              <p id="height">{user?.height ? `${user?.height}cm` : ""}</p>
            )}
            {user?.height && <span className="circle-separator">•</span>}
            {user?.weight && (
              <p id="weight">{user?.weight ? `${user?.weight}kg` : ""}</p>
            )}
            {user?.weight && <span className="circle-separator">•</span>}
            <p id="nationality">
              {user?.nationality ? user?.nationality : ""}{" "}
            </p>
            {/* {userData?.map((item, i) => (
              <>
                <p id={item?.id}>{item?.title}</p>
                {i < userData?.length - 1 && (
                  <span className="circle-separator">•</span>
                )}
              </>
            ))} */}
          </div>
          {user?.playing_position && (
            <div className="d-flex flex-row align-items-center flex-nowrap content-row">
              <p id="playing-position">
                {user?.playing_position ? user?.playing_position : ""}
              </p>
            </div>
          )}
          <div className="d-flex flex-row align-items-center flex-nowrap content-row">
            <p id="current-club">{user?.club?.name ? user?.club?.name : ""}</p>
            <Image
              id={"current-club-badge"}
              className={`current-club-badge`}
              src={user?.club?.image ? user?.club?.image : ""}
            />
          </div>
          <div className="d-flex flex-row align-items-center flex-nowrap content-row contact-button-row">
            <div
              id="contact-me-btn"
              className="contact-button d-flex justify-content-center align-items-center cursor-pointer"
            >
              <p id="contact-me-text" className="white">
                Contact Me
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-right about-me-image">
          <Image
            id={"profile-image"}
            className={`border-20 w-100`}
            src={
              user?.profile_image
                ? user?.profile_image
                : "https://routeresourcegroup.blob.core.windows.net/image/user-avatar-placeholder.png"
            }
          />

<MiscAttributes Category="about me" />
        </div>
      </div>
    </>
  );
};

export default AboutMe;
