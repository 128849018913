import AboutMe from "components/AboutMe";
import Agent from "components/Agent";
import BioVideo from "components/BioVideo";
import Education from "components/Education";
import FootballStats from "components/FootballStats";
import FourCornerModel from "components/FourCornerModel";
import HighlightsGallery from "components/HighlightsGallery";
import Interests from "components/Interests";
import { INSTITUTE_ID_ACADEMIC_SPORT } from "components/pages/constants";
import PersonalDevelopment from "components/PersonalDevelopment";
import Popup from "components/Popup";
import PreviousClubs from "components/PreviousClubs";
import References from "components/References/References";
import TopLevelVideo from "components/TopLevelVideo";
import TransferableSkills from "components/TransferableSkills";
import { useSelector } from "react-redux";

const Interactive = () => {
  let { user } = useSelector((state) => state.user);

  // check the user object in agility and speed key then show data
  // if (user && typeof user === "object") {
  //   var desiredKeys = [
  //     "agility",
  //     "speed",
  //     "coached_minutes_overall",
  //     "coached_minutes_this_season",
  //     "game_time_overall",
  //     "game_time_season",
  //     "footballMedia",
  //     "bioVideo",
  //   ];
  //   var hasDesiredKey = desiredKeys.some(function (key) {
  //     return Object.keys(user).includes(key);
  //   });
  // }

  return (
    <>
      <div
        id="main-container"
        className="position-absolute d-flex flex-column justify-content-center">
        <div id="cvContainer" className="container d-flex flex-column">
          <AboutMe />
          {user && user?.bioVideo && user?.bioVideo !== "" && <BioVideo />}
          {user?.courseData?.length > 0 && <Education />}
          {/* {hasDesiredKey && <BioVideo />} */}
          {user?.topLevelVideos?.length > 0 && <TopLevelVideo />}
          {user?.interests?.length > 0 && <Interests />}

          {(user?.trips_events_experiences?.length > 0 ||
            user?.presentation_awards?.length > 0 ||
            user?.community_charity_support?.length > 0) && (
            <PersonalDevelopment />
          )}
          {/* {user?.organization === "football" &&
            user?.footballMedia?.length > 0 && <HighlightsGallery />} */}
          {user?.footballAttribute === true && (
            <>
              {user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT &&
                user?.previous_clubs?.length > 0 && <PreviousClubs />}

              {user?.organization === "football" && (
                <>
                  <FootballStats /> <FourCornerModel />
                </>
              )}
            </>
          )}
          {user?.transferableSkills?.length > 0 && <TransferableSkills />}
          {((user?.cvOverview && Object.keys(user.cvOverview).length > 0) ||
            user?.references?.length > 0) && <References />}

          {user?.club?.instituteId === INSTITUTE_ID_ACADEMIC_SPORT &&
            user?.agent?.length > 0 && <Agent />}
        </div>
      </div>

      <Popup />
    </>
  );
};

export default Interactive;
