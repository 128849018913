import $ from "jquery";

/* football stats */
export const footballStatsExpandInfo = () => {
  $(document).ready(function () {
    $(".expand-collapse-row-football-stats").click(function () {
      var stats = $(this).next(".stat-box");
      var expandText = $(this).find(".expand-collapse-text");

      // console.log("Clicked expand-collapse-row-football-stats");
      // console.log("Stats:", stats);
      // console.log("Expand text:", expandText);

      if (stats.hasClass("active")) {
        // Collapse the card
        stats.removeClass("active");
        expandText.text("Expand Info");
      } else {
        // Collapse any previously expanded cards
        $(this)
          .closest(".row")
          .siblings()
          .find(".stat-box")
          .removeClass("active");
        $(this)
          .closest(".row")
          .siblings()
          .find(".expand-collapse-text")
          .text("Expand Info");

        // Expand the current card
        stats.addClass("active");
        expandText.text("Collapse Info");
      }
    });
  });
};
