import React from "react";

const Icons = (props) => {
  const { width, height, name } = props;
  return (
    <>
      {name === "phone" && (
        <svg
          className="phone-icon contact-icon"
          xmlns="http://www.w3.org/2000/svg"
          width={width ? width : "19.001"}
          height={height ? height : "19.002"}
          viewBox="0 0 19.001 19.002"
          fill="#006b00"
        >
          <path
            className="a"
            d="M19,13.8a3.013,3.013,0,0,1-.887,2.143L16.56,17.5a5.1,5.1,0,0,1-3.6,1.5,4.9,4.9,0,0,1-.815-.068A14.714,14.714,0,0,1,.068,6.856,5.076,5.076,0,0,1,1.5,2.442L3.055.887a3.033,3.033,0,0,1,4.9.889A4.434,4.434,0,0,1,6.337,7.312a12.405,12.405,0,0,0,5.353,5.353,4.434,4.434,0,0,1,5.535-1.623A3.041,3.041,0,0,1,19,13.8m-1.583,0a1.452,1.452,0,0,0-.849-1.319,2.844,2.844,0,0,0-3.662,1.228l-.2.365a.794.794,0,0,1-1.018.346A14,14,0,0,1,4.579,7.308.793.793,0,0,1,4.927,6.29l.361-.2A2.842,2.842,0,0,0,6.517,2.432a1.449,1.449,0,0,0-2.343-.426L2.62,3.561a3.488,3.488,0,0,0-.99,3.033A13.13,13.13,0,0,0,12.408,17.371a3.488,3.488,0,0,0,3.033-.99l1.554-1.554a1.437,1.437,0,0,0,.424-1.024"
            transform="translate(0)"
          />
        </svg>
      )}

      {name === "e-mail" && (
        <svg
          className="email-icon contact-icon"
          xmlns="http://www.w3.org/2000/svg"
          width={width ? width : "19.001"}
          height={height ? height : "14.251"}
          viewBox="0 0 19.001 14.251"
          fill="#006b00"
        >
          <path
            className="a"
            d="M19,32.407a2.368,2.368,0,0,0-.682-1.663l-.013-.017-.017-.013a2.36,2.36,0,0,0-1.663-.682H2.375a2.368,2.368,0,0,0-1.664.682L.7,30.727l-.013.017A2.36,2.36,0,0,0,0,32.407v9.5a2.361,2.361,0,0,0,.686,1.666l.01.013.011.01a2.368,2.368,0,0,0,1.668.686H16.626a2.364,2.364,0,0,0,1.666-.686l.013-.01.01-.013A2.361,2.361,0,0,0,19,41.908Zm-17.418.329,4.423,4.422L1.583,41.579ZM9.5,38.413l-6.8-6.8H16.3Zm-2.375-.137L8.94,40.093a.791.791,0,0,0,1.119,0l1.816-1.816L16.3,42.7H2.7Zm5.869-1.119,4.422-4.422v8.843Z"
            transform="translate(0 -30.032)"
          />
        </svg>
      )}

      {name === "popup" && (
        <svg
          className="close-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="22.828"
          height="22.828"
          viewBox="0 0 22.828 22.828"
        >
          <g
            id="Group_6215"
            data-name="Group 6215"
            transform="translate(-1133.586 -25.586)"
          >
            <line
              id="Line_73"
              data-name="Line 73"
              x2="20"
              y2="20"
              transform="translate(1135 27)"
              fill="none"
              stroke="#006b00"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_74"
              data-name="Line 74"
              y1="20"
              x2="20"
              transform="translate(1135 27)"
              fill="none"
              stroke="#006b00"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default Icons;
