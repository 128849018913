import { moduleImg } from "components/Education/constants";
import Image from "components/Image";
import Attachment from "components/Education/ExpandInfo/Attachment/Attachment";

const ModuleAttachment = ({
  unitAttachmentData,
  index,
  indexOfUnit,
  indexOfCourse,
}) => {
  return (
    <div
      // id="course-1-unit-1-module-1-attachments"
      id={`course-${indexOfCourse}-unit-${indexOfUnit + 1}-module-${
        index + 1
      }-attachments`}
      className="w-100 education-attachment-list d-flex flex-column gap-25 flex-nowrap justify-content-start hidden"
    >
      <div className="grade-attachment align-items-center d-flex flex-row gap-50 w-100 flex-nowrap">
        <div className="col-md-6 flex-unset flex-row d-flex p-0 gap-10">
          <div className="col-md-3 flex-unset flex-row d-flex unit p-0 align-items-center unit-icon-div">
            <Image
              className="unit-icon predicted-icon"
              src={
                unitAttachmentData?.icon
                  ? unitAttachmentData?.icon
                  : moduleImg[1].image
              }

              // src={index % 2 === 1 ? moduleImg[1].image : moduleImg[0].image}
            />
          </div>

          <div className="col-md-9 d-flex flex-column align-items-start p-0 justify-content-center">
            <p className="course-title">{unitAttachmentData?.name}</p>
          </div>
        </div>
        <div className="col-md-6 flex-unset flex-row d-flex p-0 align-items-center">
          <div className="col-md-12 d-flex flex-row align-items-start p-0 justify-content-center gap-50">
            <p className="grade-text achieved">
              {/* {unitAttachmentData?.gradeTitle} */}
              {unitAttachmentData?.achivedGrade === null
                ? unitAttachmentData?.predictedGrade === null
                  ? ""
                  : "Predicted Grade"
                : "Achieved Grade"}
            </p>
            <p className="grade-result achieved font-weight-bold">
              {/* {unitAttachmentData?.grade} */}
              {unitAttachmentData?.achivedGrade === null
                ? unitAttachmentData?.predictedGrade === null
                  ? ""
                  : unitAttachmentData?.predictedGrade?.toUpperCase()
                : unitAttachmentData?.achivedGrade?.toUpperCase()}
            </p>
          </div>
        </div>
      </div>
      <div className="attachment-separator"></div>

      {unitAttachmentData?.tutorComments?.length > 0 &&
        unitAttachmentData?.tutorComments?.map((data, i) => (
          <>
            <Attachment attachmentsData={data} />
            {i < unitAttachmentData?.tutorComments?.length - 1 && (
              <div className="attachment-separator"></div>
            )}
          </>
        ))}
      {unitAttachmentData?.tutorComments?.length > 0 && (
        <div className="attachment-separator"></div>
      )}

      {unitAttachmentData?.attachments
        ?.filter((item) => item?.type === "text")
        .map((data, i) => (
          <>
            <Attachment attachmentsData={data} />
            {i <
              unitAttachmentData?.attachments?.filter(
                (item) => item?.type === "text"
              )?.length && <div className="attachment-separator"></div>}
          </>
        ))}

      {unitAttachmentData?.attachments
        ?.filter((item) => item?.type !== "text")
        .map((data, i) => (
          <>
            <Attachment attachmentsData={data} />
            {i <
              unitAttachmentData?.attachments?.filter(
                (item) => item?.type !== "text"
              )?.length -
                1 && <div className="attachment-separator"></div>}
          </>
        ))}
    </div>
  );
};

export default ModuleAttachment;
